import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.prototype.$http = axios
Vue.config.productionTip = false

axios.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')

    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken.replace(/['"]+/g, '')}`
    }
    return config
  },
  error => Promise.reject(error),
)

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      const status = error.response.status
      if (status === 401 || status === 403) {
        localStorage.removeItem('accessToken')
        router.push('/')

        if (error.response.data.message === 'Unauthenticated.') {
          const successMessage = document.createElement('div');
          successMessage.textContent = 'Sua sessão expirou, por favor faça novo login!';
          successMessage.className = 'warn-message';

          successMessage.style.position = 'fixed';
          successMessage.style.top = '10px';
          successMessage.style.right = '10px';
          successMessage.style.background = 'orange';
          successMessage.style.color = 'white';
          successMessage.style.padding = '10px';
          successMessage.style.borderRadius = '4px';

          document.body.appendChild(successMessage);

          setTimeout(() => {
            document.body.removeChild(successMessage);
          }, 3800);
        }
      }
    }
    return Promise.reject(error);
  })

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')